import React, { Fragment, useRef } from 'react'
import RichText from '/src/utils/RichText'
import FluidImage from '/src/utils/FluidImage'
import Heading from '../Heading'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

const WithLargeImagesPopup = (props) => {
    const closeRef = useRef(null)

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-[99999999999] inset-0 overflow-y-auto"
                initialFocus={closeRef}
                open={props.open}
                onClose={() => props.handleSetOpen(false)}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center lg:block lg:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden lg:inline-block lg:align-middle lg:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                        enterTo="opacity-100 translate-y-0 lg:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 lg:scale-100"
                        leaveTo="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white px-6 md:px-16 lg:px-20 xl:px-28 py-8 md:py-12 lg:py-20 text-left overflow-hidden shadow-xl transform transition-all lg:my-8 lg:mx-4 lg:align-middle lg:max-w-6xl font-poppins w-[90%]">
                            <div className="block absolute top-0 right-0 pt-4 lg:pt-8 pr-4 lg:pr-8">
                                <button
                                    type="button"
                                    className="bg-white rounded text-orange hover:text-darkorange outline-none focus:outline-none"
                                    onClick={() => props.handleSetOpen(false)}
                                    initialFocus={closeRef}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-7 w-7" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="flex lg:flex-nowrap flex-wrap justify-center items-start lg:gap-20 gap-8 md:pt-0 pt-8">
                                <div className="w-full lg:w-6/12">
                                    <div className="relative group">
                                        <FluidImage image={props.content.image} className={`object-cover z-10 opacity-100 group-hover:opacity-0 duration-300`}/>
                                        <FluidImage image={props.content.hover_image} className={`object-cover w-full h-full !absolute top-0 left-0`}/>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12">
                                    <Heading size="h3" className="!mb-1 lg:text-3xl">{props.name}</Heading>
                                    <p className="text-[#9C9C9C] text-base font-light leading-none">{props.content.job_title}</p>
                                    <div className="mt-8 text-midnight">
                                        {props.content.popup_copy.map((item) => (
                                            <div className="mb-4" key={item._uid}>
                                                <Heading size="h5" className="!mb-1 lg:text-lg !text-lg font-medium">{item.title}</Heading>
                                                <div className="text-base prose max-w-full font-light leading-snug">
                                                    <RichText>{item.copy}</RichText>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
};


export default WithLargeImagesPopup