import React from "react"
import Link from 'gatsby-link'
import SbEditable from 'storyblok-react'
import Logo from "static/primary-logo.png"
import Hexagons from "src/images/hexagon-orange-top.svg"
import ButtonArrow from "src/images/button_arrow.svg"
import MembersLogin from "src/components/MembersLogin"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import resolveLink from 'src/utils/resolveLink'

const Footer = ({ settings }) => {

  const identity = useIdentityContext()
    
  return (
    <footer className="bg-white text-midnight overflow-hidden ">      
        <div className="bg-lightgrey py-12 relative hidden">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <Link className="group" to="/contact">
                    <div className="font-light lg:text-3xl text-2xl tracking-tight">Starting a new project?</div>
                    <div className="font-normal lg:text-5xl text-4xl text-orange duration-300 group-hover:text-darkorange tracking-tight">Let's chat.</div>
                    <div className="text-orange group-hover:text-darkorange mt-3">              
                        <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 fill-current" width="20" height="21" />
                    </div>
                </Link>
            </div>
            <div className="absolute -top-7 -right-5 hidden sm:block text-orange hidden"><Hexagons className="w-[600px]"/></div>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 ">    
        <div className="flex py-4">
            <div className="mr-right"><img src={Logo} alt="Generation Media Logo" className="w-48"/></div>
        </div>     
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
            <div className="col-span-1 md:col-span-3 lg:col-span-2 break-all text-sm font-light pb-2">
                <div className="py-4">           
                    7-8 Midford Place, London W1T 5BG               
                </div>
                <div>
                    <a href="tel:02073077900" className="text-orange sm:text-midnight hover:text-orange duration-300">           
                        <span className="text-orange font-normal">T:</span> +44 (0) 207 307 7900               
                    </a>
                </div>
                <div>
                    <a href="mailto:contact@generationmedia.co.uk" className="text-orange sm:text-midnight hover:text-orange duration-300">           
                        <span className="text-orange font-normal">E:</span> contact@generationmedia.co.uk         
                    </a>
                </div>         
            </div>
            {settings.content.footer_navigation.map((section) => (
                <nav className="hidden" key={section._uid}>
                    {section.links.map((item) => (
                        <SbEditable content={item} key={item.name}>
                            <div className="pb-2">
                                <Link to={resolveLink(item.target?.cached_url)} className="text-sm font-light hover:text-orange duration-300">
                                    {item.name}
                                </Link>
                            </div>
                        </SbEditable>
                    ))}
                </nav>
            ))}
            <nav className="hidden md:hidden">
                <div className="pr-4 pb-2">
                    <a href="https://www.facebook.com/generationmedia/" className="group" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook duration-300  group-hover:text-orange"></i>
                    </a>
                </div>
                <div className="pr-4 md:px-0 pb-2">
                    <a href="https://twitter.com/genmediauk" className="group" target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter duration-300 group-hover:text-orange"></i>
                    </a>
                </div>
                <div className="pr-4 md:px-0 pb-2">
                    <a href="https://www.linkedin.com/company/generation-media/" className="group" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin duration-300 group-hover:text-orange"></i> 
                    </a>
                </div>
                <div className="pr-4 pb-2">
                    <a href="https://www.instagram.com/generat10n_media/" className="group" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram duration-300 group-hover:text-orange"></i>
                    </a>
                </div>
            </nav>
            <div className="hidden text-sm ml-none lg:ml-auto col-span-1 md:col-span-2 lg:col-span-1">
                <MembersLogin />
                {
                    identity.user?.app_metadata?.roles?.includes('admin') &&
                        <Link to="/admin/" className="inline-block text-xs font-light underline cursor-pointer mt-3">Manage users</Link>
                }
            </div>
        </div>
        <div className="block md:flex items-end w-full">
            <div className="text-xs font-light mr-auto text-left py-2">
                <p className="mt-8">
                    Copyright ©  Generation Media Ltd 2022.
                </p> 
                <p>
                    Registered in England No: 04342267
                </p>
            </div>
            <div className="ml-auto py-2 text-left text-xs font-light">
                <p className="mt-0 sm:mt-8">Website by <a className="text-orange hover:text-darkorange duration-300" href="https://www.thebiggerboat.co.uk">The Bigger Boat</a></p>
            </div>
        </div>      
      </div>
    </footer>
  )
}

export default Footer
