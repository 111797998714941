import React from "react";
import SbEditable from "storyblok-react";
import FluidImage from 'src/utils/FluidImage'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import Button from 'src/components/Button'
import RichText from 'src/utils/RichText'
import { LabelColour } from 'src/utils/ColourUtilities'

const SimpleCenteredBranded = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="relative py-28 overflow-hidden">
                <div className="absolute inset-0">
                    <FluidImage className="w-full h-full object-cover" image={blok.background_image}/>                          
                </div>
                <div className="relative mx-auto text-center  bg-white rounded-full z-10 h-[320px] w-[320px] sm:h-[450px] sm:w-[450px]">
                    <div className="absolute inset-0 m-auto h-[min-content] px-12">
                        <Label className={`${LabelColour(blok.label_colour)}`}>
                            {blok.label}
                        </Label>
                        <Heading size="h3" className="text-[24px] sm:text-2xl !mb-4 font-medium">
                            {blok.title}
                        </Heading>
                        <div className="text-base text-midnight mb-4 font-light tracking-tight leading-snug">
                            <RichText>
                                {blok.content}
                            </RichText>
                        </div>
                        <Button className="text-orange !pr-0 hover:text-darkorange" to={blok.button_target.cached_url}>
                            {blok.button_text}
                        </Button>
                    </div>
                </div>
            </div>
        </SbEditable>
    );
};

export default SimpleCenteredBranded;