import React from "react"
import Header from './Header'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
import StoryblokService from '../utils/storyblok-service'
import favicon from 'static/gm_favicon.png'
import GetSettings from 'src/utils/GetSettings'
import AuthOverlay from "./AuthOverlay"

export default function Layout({ children, location, isEditor }){

  let parsedSetting = GetSettings()

  return (
    <div className="bg-white font-fixel text-midnight">
      { isEditor ? 
        (<>
        <Helmet
            script={[
              {"src": `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`,
              "type": "text/javascript"}
            ]}
        />
        <Helmet
            script={[
              {
              "innerHTML": `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
              "type": "text/javascript"
              }
            ]}
        />
        </>) : null
      }
      <Helmet>
        <link rel="icon" href={favicon} />
        <script src="https://kit.fontawesome.com/e86587c99a.js" crossorigin="anonymous"></script>
      </Helmet>
      <AuthOverlay />
      <Header settings={parsedSetting} lang={parsedSetting.activeLanguage} />
      <main>
        {children}
      </main>
      <Footer settings={parsedSetting} />
    </div>
  )
}