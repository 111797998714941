import React, { useState, useEffect, useContext } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuAlt1Icon, XIcon } from '@heroicons/react/outline'
import Link from 'gatsby-link'
import SbEditable from 'storyblok-react'
import LogoDefault from "static/secondary-logo.png"
import LogoWhite from "static/secondary-logo.png"
import resolveLink from 'src/utils/resolveLink'
import Button from 'src/components/Button'
import Hexagons from "src/images/hexagon-white-bottom.svg"
import { PageContext } from 'src/utils/context'

const Navigation = ({ settings, lang }) => {

  const { hasHero, currentPage } = useContext(PageContext)

  const [scrollLock, setScrollLock] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  // Change the style of the navbar on scroll
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  // Bundle up three different utilities into one useEffect
  useEffect(() => {
    // Check for scroll
    window.addEventListener("scroll", handleScroll)
    // Disable body scrolling
    if ( scrollLock ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
    // https://github.com/tailwindlabs/tailwindcss/discussions/1610#discussioncomment-352836
    // gets screen size - to fix mobile viewport height problem
    if (typeof window !== 'undefined') {

      function handleResize() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [scrolled, scrollLock])

  const Logo = !hasHero || scrolled ? LogoDefault : LogoWhite

  return (
    <SbEditable content={settings} key={settings._uid}>
      {!hasHero && <div className="lg:mb-[102px] mb-[69px]"></div>}
      <Popover 
  className={`w-full fixed top-0 z-[9999999999] ${!hasHero ? "bg-sunset" : scrolled ? "bg-sunset" : "bg-transparent"}`}
>

        {({ open }) => (
          <>
            <header className="mx-auto px-6 lg:px-12">
              <nav className={`flex justify-between items-center lg:justify-start lg:space-x-10 transition-all duration-300 ${scrolled ? 'lg:py-4 py-3' : 'lg:py-8 py-4'}`}>
                <div className="flex justify-start items-center lg:flex-1">
                  <Link to="/">
                    <span className="sr-only">Generation Media</span>
                    <img
                      className="w-40"
                      src={Logo}
                      alt="Generation Media Logo"
                    />
                  </Link>
                </div>
                {!open && (
                  <div className="-mr-2 -my-2 hidden lg:hidden focus:outline-none outline-none" onClick={() => setScrollLock(!scrollLock)} onKeyDown={() => setScrollLock(!scrollLock)} role="button" tabIndex="0">
                    <Popover.Button className={`p-2 inline-flex items-center justify-center focus:outline-none outline-none ${ hasHero && !scrolled ? "text-white" : "text-midnight" }`}>
                      <span className="sr-only">Open menu</span>
                      <MenuAlt1Icon className="header-menu-icon h-8 w-8 focus:outline-none outline-none" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                )}
                <div className="lg:hidden hidden flex-wrap flex-col">
                  {/*<ul className="flex ml-auto mb-4 gap-2">
                    <li>
                      <Link to="/" 
                      className={`block ${lang === 'en' ? "bg-primary text-white" : ""} ${lang === "en" ? "opacity-100" : "opacity-50"}`}
                      >
                        <span className="flag-icon flag-icon-gb"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/de" 
                      className={`block ${lang === 'de' ? "bg-primary text-white" : ""} ${lang === "de" ? "opacity-100" : "opacity-50"}`}
                      >
                        <span className="flag-icon flag-icon-de"></span>
                      </Link>
                    </li>
                  </ul>*/}                            
                  <div className="hidden lg:hidden space-x-6 items-center">
                    {settings.header_navigation.map((item) => (
                      <SbEditable content={item} key={item._uid}>
                        <Link to={resolveLink(item.target.cached_url)} className={`group text-sm font-light leading-[18px] tracking=[-1px] duration-300 ${ hasHero && !scrolled ? "text-white" : "text-midnight hover:text-orange" }`}>
                          {item.name}
                          <div className={`mt-[5px] h-px group-hover:w-full duration-300 ${item.target.id === currentPage ? 'w-full' : 'w-0'} ${ hasHero && !scrolled ? "bg-transparent" : "bg-black group-hover:bg-orange" }`}></div>
                        </Link>
                      </SbEditable>
                    ))}
                  </div>
                </div>                
              </nav>
            </header>
            {/* Mobile menu starts here */}
            <Transition
                enter="duration-300 ease-in-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 ease-in-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Popover.Panel static className="absolute top-0 inset-x-0 lg:hidden z-20 h-screen overflow-hidden">
                <div className="bg-orange h-screen">
                  <div className={`px-6 ${scrolled ? 'py-3' : 'py-4'} flex flex-col items-center justify-between h-full`}>
                    <div className="w-full overflow-y-scroll overflow-x-hidden">
                      <div className="flex items-center justify-between w-full mb-6">
                        <div>
                          <img
                              className="w-40"
                              src={LogoWhite}
                              alt="Summit Training Logo"
                          />
                        </div>
                        <div className="-mr-2 focus:outline-none outline-none" onClick={() => setScrollLock(!scrollLock)} onKeyDown={() => setScrollLock(!scrollLock)} role="button" tabIndex="0">
                          <Popover.Button className="p-2 pt-0 inline-flex items-center justify-center text-white focus:outline-none outline-none">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="header-menu-icon h-8 w-8 focus:outline-none outline-none" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="w-full overflow-y-scroll overflow-x-hidden">
                      <nav className="grid">
                        {settings.header_navigation.map((item) => (
                          <SbEditable content={item} key={item._uid}>
                            <Link 
                                to={`${item.target.cached_url === "home" ? "" : resolveLink(item.target.cached_url)}`} 
                                className="text-2xl font-light leading-[30px] tracking=[-1px] text-white py-3">
                                {item.name}
                            </Link>
                          </SbEditable>
                        ))}
                      </nav>
                    </div>
                    <Button to="members" className="mt-6 text-white mr-auto text-2xl font-normal z-10" iconClass="w-[20px]">Members</Button>
                  </div>
                  <Hexagons className="absolute bottom-0 right-0 scale-x-[-1] opacity-50 w-5/6"/>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </SbEditable>
  )
}

export default Navigation;
