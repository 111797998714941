import React, { useState } from "react"
import Layout from "../components/Layout"
import {  PageContext } from '../utils/context'
import Heading from "../components/Heading"
import { useForm } from "react-hook-form"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { navigate, Link } from 'gatsby'
import ButtonArrow from "src/images/button_arrow.svg"
import Seo from "src/utils/seo"

const Register = ({ location }) => {

    const identity = useIdentityContext()
    const { register, handleSubmit, formState: { errors }} = useForm()
    const [formError, setFormError] = useState(false)
    const [signingUp, setSigningUp] = useState(false)
  
    const onSubmit = async (data) => {
      setSigningUp(true)
      setFormError(false)

      identity
        .signup(data)
        .then(() => {
          setSigningUp(false)
          navigate('/')
        })
        .catch(e => {
          setFormError(e.message)
          setSigningUp(false)
        })
    }

    return (
        <PageContext.Provider
            value={{
                hasHero: false,
                currentPage: false,
                location
            }}
        >
            <Seo title={'Members Registration'} description={'Register to access exclusive member’s content.'} />
            <Layout location={location}>
                <div className="max-w-7xl mx-auto lg:py-24 py-12 px-6">
                    <div className="flex lg:flex-row flex-col gap-x-16">
                        <div className="lg:w-6/12 w-full">
                            <Heading size="h2">Members Registration</Heading>
                            <p className="font-light max-w-2xl lg:text-xl text-base leading-7">Register to access exclusive member’s content.</p>
        
                            <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>

                                <div className="mb-4">
                                    <label htmlFor="user_metadata.full_name" className="block font-light mb-2">
                                        Full Name
                                        <span className="text-orange"> *</span>
                                    </label>
                                    <input
                                        {...register('user_metadata.full_name', { required: true })}
                                        className={`appearance-none py-3 px-4 block w-full placeholder-gray-300 text-midnight focus:ring-transparent focus:border-orange border-gray-200 ${signingUp && 'disabled'}`}
                                        type="text"
                                        placeholder="Full name"
                                        name="user_metadata.full_name">
                                    </input>
                                    {errors.user_metadata?.full_name && <p className="text-red-500 text-xs italic mt-1">Name is required</p>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email" className="block font-light mb-2">
                                        Email
                                        <span className="text-orange"> *</span>
                                    </label>
                                    <input
                                        {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        className={`appearance-none py-3 px-4 block w-full placeholder-gray-300 text-midnight focus:ring-transparent focus:border-orange border-gray-200 ${signingUp && 'disabled'}`}
                                        type="text"
                                        placeholder="Email"
                                        name="email">
                                    </input>
                                    {errors.email && <p className="text-red-500 text-xs italic mt-1">Email is required, use correct format</p>}
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="password" className="block font-light mb-2">
                                        Password
                                        <span className="text-orange"> *</span>
                                    </label>
                                    <input
                                        {...register('password', { required: true })}
                                        className={`appearance-none py-3 px-4 block w-full placeholder-gray-300 text-midnight focus:ring-transparent focus:border-orange border-gray-200 ${signingUp && 'disabled'}`}
                                        name="password"
                                        type="password"
                                        placeholder="Password">
                                    </input>
                                    {errors.password && <p className="text-red-500 text-xs italic mt-1">Password is required</p>}
                                </div>

                                <div className="flex items-center justify-between mt-12">
                                    <button type="submit" className={`text-2xl flex duration-300 text-orange group hover:text-darkorange cursor-pointer ${signingUp && 'opacity-50 cursor-not-allowed'}`}>        
                                        Register               
                                        <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 ml-2 mt-2 fill-current" width="18" height="19" />
                                    </button>                                         
                                </div>

                                <div className="pt-2">
                                    {formError && <p className="text-red-500 text-xs italic">That didn't seem to work right!</p>}
                                </div>

                                <div className="lg:mt-12 mt-6">
                                    <p className="font-light text-base">Already a member? <Link className="text-orange hover:text-darkorange duration-300" to="/login/">Login now</Link></p>
                                </div>                                
                            </form>                                          
                        </div>
                        <div className="lg:w-6/12 w-full">
                            <img src="https://a.storyblok.com/f/138741/727x554/b7a6179c49/generation-media-members-registration-727x554.jpg" loading="lazy" alt="Members login"/>
                        </div>
                    </div>
                </div>
            </Layout>        
        </PageContext.Provider>
    )
}

export default Register